/*
 *** 系統業績報表 AKA 系統制繼續率發放資格
 */
const annualFilterTitles = {
    receiveEndDate: '受理日(迄)(當年第一天開始)',
    ssEndDate: '核實月(迄)(當年第一月開始)',
    dataType: '業績類別',
    relationMode: '繼續率發放資格',
    _seq: ['endDate', 'dataType', 'relationMode']
}

const annualColumns = {
    serialNumber: {
        label: '編號',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'center',
        width: 8,
        widthPx: 50
    },
    source: {
        label: '業績來源',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 14,
        widthPx: 90
    },
    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 98
    },
    name: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 16,
        widthPx: 105
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 90
    },
    salesTitle: {
        label: '職級',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 100
    },
    fybPersistency: {
        label: '累計受理FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 18,
        widthPx: 118
    },
    recommenderName: {
        label: '推薦人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 110
    }
}

const annualDescriptions = (dataType, title) => {
    const receivedOrSs = dataType === '1' ? '受理' : '已發佣'
    const des1 =
        dataType === '1'
            ? '本表所稱「累計受理FYB」，係指「受理日在累計區間內之保單業績總和」，保單狀態為未註銷。' // 受理
            : '本表所稱「累計已發佣FYB」，係指「首年度核實且已發佣之業績總和」。' // 核實
    const des2 =
        title === 'supervisor'
            ? `若轄下成員K隸屬不同分行或與您的職級為平階以上，且該成員K擁有轄下夥伴，則報表中以「K及其系統業績」文字顯示並計算該成員K與其轄下夥伴之業績總和。` // 主管
            : `系統業績組織：依「累計${receivedOrSs}FYB」由高而低列出排名前10名之系統(累積業績為0者不列出)。若「累計${receivedOrSs}FYB」超過500萬元者超過10位，則全部列出，不受前10名之限制。` // 分行
    const des3 =
        '區群、直轄主管為已離職或尚未報聘登錄完成之狀態，且當年度無業績，則不顯示於報表中。'
    return [des1, des2, des3]
}

/*
 *** 單位業績明細表 ***
 */
const detailFilterTitles = {
    endDate: '受理月',
    relationMode: '組織種類',
    _seq: ['endDate', 'relationMode']
}

const detailColumns1 = {
    salesName: {
        label: '業務員姓名',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 180,
        width: 4
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 90,
        width: 4
    },
    license: {
        label: '證照',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 75,
        width: 4
    },
    titleTrans: {
        label: '職級代號',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 90,
        width: 4
    },
    registerDate: {
        label: '登錄日',
        sortEnabled: false,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 106,
        width: 4,
        divider: true
    },
    /*
     *** WARNING ***
     *** 組織只會加總position=right的欄位 ***
     *** 邏輯在DetailTable1.vue -> getSumColumn ***
     */
    // monthlyReceiveLifeFyp: {
    //     label: '當月受理 壽險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlyReceiveLifeFyb: {
        label: '當月受理 壽險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlyReceiveLifeFyn: {
        label: '當月受理 壽險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // monthlyReceivePropertyFyp: {
    //     label: '當月受理 產險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlyReceivePropertyFyb: {
        label: '當月受理 產險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlyReceivePropertyFyn: {
        label: '當月受理 產險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 90,
        width: 4,
        divider: true
    },
    // lastMonthlyReceiveLifeFyp: {
    //     label: '上月受理 壽險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlyReceiveLifeFyb: {
        label: '上月受理 壽險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlyReceiveLifeFyn: {
        label: '上月受理 壽險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // lastMonthlyReceivePropertyFyp: {
    //     label: '上月受理 產險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlyReceivePropertyFyb: {
        label: '上月受理 產險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlyReceivePropertyFyn: {
        label: '上月受理 產險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        widthPx: 90,
        unit: '件',
        width: 4,
        divider: true
    },
    // lastMonthlySsLifeFyp: {
    //     label: '上月核實 壽險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlySsLifeFyb: {
        label: '上月核實 壽險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlySsLifeFyn: {
        label: '上月核實 壽險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // lastMonthlySsPropertyFyp: {
    //     label: '上月核實 產險實收保費',
    //     sortEnabled: false,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlySsPropertyFyb: {
        label: '上月核實 產險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlySsPropertyFyn: {
        label: '上月核實 產險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 90,
        width: 4,
        divider: true
    },
    annualLifeFyb: {
        label: '當年度累積 首年壽險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 120,
        width: 4
    },
    annualLifeFyn: {
        label: '當年度累積 壽險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 100,
        width: 4
    },
    annualPropertyFyb: {
        label: '當年度累積 首年產險FYC',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        widthPx: 120,
        width: 4
    },
    annualPropertyFyn: {
        label: '當年度累積 產險件數',
        sortEnabled: false,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '件',
        widthPx: 100,
        width: 4
    }
}

const detailColumns2 = {
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'left',
        total: 'key',
        widthPx: 220,
        width: 4,
        isUnmovable: true,
        isUnhide: true
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 95,
        width: 4
    },
    license: {
        label: '證照',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 95,
        width: 4,
        alias: {
            licenseTrans: {
                label: '具備證照',
                position: 'center'
            }
        }
    },
    titleTrans: {
        label: '職級代號',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 90,
        width: 4
    },
    registerDate: {
        label: '登錄日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 106,
        divider: true,
        width: 4
    },
    /*
     *** WARNING ***
     *** 組織只會加總position=right的欄位 ***
     *** 邏輯在DetailTable2.vue -> getSumColumn ***
     */
    // monthlyReceiveLifeFyp: {
    //     label: '當月受理 壽險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlyReceiveLifeFyb: {
        label: '當月受理 壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlyReceiveLifeFyn: {
        label: '當月受理 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // monthlyReceivePropertyFyp: {
    //     label: '當月受理 產險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlyReceivePropertyFyb: {
        label: '當月受理 產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlyReceivePropertyFyn: {
        label: '當月受理 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        divider: true,
        width: 4
    },
    // monthlySsLifeFyp: {
    //     label: '當月核實 壽險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlySsLifeFyb: {
        label: '當月核實 壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlySsLifeFyn: {
        label: '當月核實 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // monthlySsPropertyFyp: {
    //     label: '當月核實 產險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    monthlySsPropertyFyb: {
        label: '當月核實 產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    monthlySsPropertyFyn: {
        label: '當月核實 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        divider: true,
        width: 4
    },
    // lastMonthlyReceiveLifeFyp: {
    //     label: '上月受理 壽險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlyReceiveLifeFyb: {
        label: '上月受理 壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlyReceiveLifeFyn: {
        label: '上月受理 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // lastMonthlyReceivePropertyFyp: {
    //     label: '上月受理 產險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlyReceivePropertyFyb: {
        label: '上月受理 產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlyReceivePropertyFyn: {
        label: '上月受理 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        divider: true,
        width: 4
    },
    // lastMonthlySsLifeFyp: {
    //     label: '上月核實 壽險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlySsLifeFyb: {
        label: '上月核實 壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlySsLifeFyn: {
        label: '上月核實 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    // lastMonthlySsPropertyFyp: {
    //     label: '上月核實 產險實收保費',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'number',
    //     position: 'right',
    //     total: 'sum',
    //     unit: '元',
    //     widthPx: 110
    // },
    lastMonthlySsPropertyFyb: {
        label: '上月核實 產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 110,
        width: 4
    },
    lastMonthlySsPropertyFyn: {
        label: '上月核實 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        divider: true,
        width: 4
    },
    annualLifeFyb: {
        label: '當年度累積 首年壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 4
    },
    annualLifeFyn: {
        label: '當年度累積 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 100,
        width: 4
    },
    annualPropertyFyb: {
        label: '當年度累積 首年產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 4
    },
    annualPropertyFyn: {
        label: '當年度累積 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 100,
        width: 4
    }
}

const detailPerformanceHelper = (row, p, roundHelper) => {
    let _p
    // 當月 受理+核實、產+壽、b+p+n
    _p = p?.currentSubmit
    row.monthlyReceiveLifeFyp = roundHelper(_p?.life?.fyp, 0) || 0
    row.monthlyReceiveLifeFyb = roundHelper(_p?.life?.fyb, 0) || 0
    row.monthlyReceiveLifeFyn = _p?.life?.fyn || 0
    row.monthlyReceivePropertyFyp = roundHelper(_p?.property?.fyp, 0) || 0
    row.monthlyReceivePropertyFyb = roundHelper(_p?.property?.fyb, 0) || 0
    row.monthlyReceivePropertyFyn = _p?.property?.fyn || 0
    _p = p?.currentCommit
    row.monthlySsLifeFyp = roundHelper(_p?.life?.fyp, 0) || 0
    row.monthlySsLifeFyb = roundHelper(_p?.life?.fyb, 0) || 0
    row.monthlySsLifeFyn = _p?.life?.fyn || 0
    row.monthlySsPropertyFyp = roundHelper(_p?.property?.fyp, 0) || 0
    row.monthlySsPropertyFyb = roundHelper(_p?.property?.fyb, 0) || 0
    row.monthlySsPropertyFyn = _p?.property?.fyn || 0
    // 上月 受理+核實、產+壽、b+p+n
    _p = p?.lastMonthSubmit
    row.lastMonthlyReceiveLifeFyp = roundHelper(_p?.life?.fyp, 0) || 0
    row.lastMonthlyReceiveLifeFyb = roundHelper(_p?.life?.fyb, 0) || 0
    row.lastMonthlyReceiveLifeFyn = _p?.life?.fyn || 0
    row.lastMonthlyReceivePropertyFyp = roundHelper(_p?.property?.fyp, 0) || 0
    row.lastMonthlyReceivePropertyFyb = roundHelper(_p?.property?.fyb, 0) || 0
    row.lastMonthlyReceivePropertyFyn = _p?.property?.fyn || 0
    _p = p?.lastMonthCommit
    row.lastMonthlySsLifeFyp = roundHelper(_p?.life?.fyp, 0) || 0
    row.lastMonthlySsLifeFyb = roundHelper(_p?.life?.fyb, 0) || 0
    row.lastMonthlySsLifeFyn = _p?.life?.fyn || 0
    row.lastMonthlySsPropertyFyp = roundHelper(_p?.property?.fyp, 0) || 0
    row.lastMonthlySsPropertyFyb = roundHelper(_p?.property?.fyb, 0) || 0
    row.lastMonthlySsPropertyFyn = _p?.property?.fyn || 0
    // 年度業績 產+壽、b+p+n
    _p = p?.annualCommit
    row.annualLifeFyb = roundHelper(_p?.life?.fyb, 0) || 0
    row.annualPropertyFyb = roundHelper(_p?.property?.fyb, 0) || 0
    row.annualLifeFyn = _p?.life?.fyn || 0
    row.annualPropertyFyn = _p?.property?.fyn || 0
    return row
}

const detailDescriptionsCommon = []

const detailDescriptions1 = [
    `■ 為缺少產險證照、 ● 為缺少投資型證照、 ▲ 為缺少外幣證照`,
    ...detailDescriptionsCommon
]

const detailDescriptions2 = [
    `<div class="license-icon description">
        <div class="life-license-icon"></div>為壽險證照、
        <div class="property-license-icon"></div>為產險證照、
        <div class="currency-license-icon"></div>為外幣證照、
        <div class="investment-license-icon"></div>為投資型證照。
    </div>`,
    ...detailDescriptionsCommon
]
const detailExcelDescriptions2 = [
    '"壽"為壽險證照、"產"為產險證照、"外"為外幣證照、"投"為投資型證照。',
    ...detailDescriptionsCommon
]

/*
 *** 當日業績速報表
 */
const dailyFilterTitles = {
    endDate: '查詢日',
    relationMode: '組織種類',
    dataType: '業績類別',
    _seq: ['endDate', 'relationMode', 'dataType']
}

const dailyColumns = {
    reportIndex: {
        label: '報件順序',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        total: 'key',
        width: 10,
        widthPx: 85
    },
    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 130
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 90
    },
    salesTitle: {
        label: '職級',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 80
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 110
    },
    mainProduct: {
        label: '主約商品',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 13,
        widthPx: 150
    },
    fyp: {
        label: '實收保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 10,
        widthPx: 120
    },
    fyb: {
        label: '查詢日受理FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 10,
        widthPx: 120,
        divider: true
    },
    fypMonthSum: {
        label: '查詢月實收保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 135
    },
    fybMonthSum: {
        label: '查詢月受理FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 10,
        widthPx: 120
    },
    fynMonthSum: {
        label: '查詢月件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        width: 6,
        widthPx: 95
    }
}

const dailyDescriptions = [
    `本報表人名順序預設將為該位夥伴當月收第一筆保單的日期自月初至月底排序，以利您觀測組織內成員報帳日分佈。`
]

/*
 *** 競賽業績查詢
 */
const contestFilterTitles = {
    relationMode: '組織種類',
    contestId: '競賽名稱',
    year: '年份',
    dataType: '業績類別',
    _seq: ['relationMode', 'year', 'contestId', 'dataType']
}

const contestColumns = {
    serialNumber: {
        label: '編號',
        sortEnabled: true,
        isFilterable: false,
        isUnmovable: true,
        type: 'number',
        position: 'center',
        total: 'key',
        width: 6,
        widthPx: 50
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 110,
        isUnmovable: true
    },
    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 110
    },
    orgBmGroupName: {
        label: '事業部',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 100
    },
    orgBmName: {
        label: '部主管',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 90
    },
    relativeDepth: {
        label: '代數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 5,
        widthPx: 50
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 140
    },
    productName: {
        label: '商品名稱',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 140
    },
    yPeriod: {
        label: '年期',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 5,
        widthPx: 60
    },
    receiveDate: {
        label: '受理日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    fyb: {
        label: '原始業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 10,
        widthPx: 115
    },
    fourWinRate: {
        label: '四贏商品加成',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 100
    },
    insureWayRate: {
        label: '投保加成',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '%',
        width: 10,
        widthPx: 100,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '行動投保/遠距投保等額外加成',
                mobile: ' 「投保加成」欄位內容為行動投保/遠距投保等額外加成'
            }
        }
    },
    isSpecial: {
        label: '特定商品',
        sortEnabled: true,
        isFilterable: true,
        position: 'center',
        width: 8,
        widthPx: 80
    },
    contestFyb: {
        label: '競賽業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 10,
        widthPx: 115
    },
    score: {
        label: '',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        width: 8,
        widthPx: 50
    },
    leaderScore: {
        label: '',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        width: 12,
        widthPx: 100
    }
}

const contestDescriptions = (isCeoMode, scoreTitle, contestId) => {
    //特定競賽規則備註

    const des1 = '競賽業績為原始業績經加成計算後四捨五入進行計算。'
    const des2 =
        '為減少計算誤差，在計算競賽業績時，將以原始業績的原始數值(可能含有小數點)計算。'
    const des3 = '特定商品不計入競賽業績(特定商品詳磊山公文)。'
    //依不同競賽顯示備註
    const des4 = `「主管獎總${scoreTitle}」欄位內容如呈現 " - "，表示該業務員不符合主管獎資格。`
    const contestRule = {
        1: [],
        2: [
            '「金起四超人競賽」不適用單位換算機制，詳細規則與獎勵辦法請參照磊山(業)字第 20220039 公文。'
        ],
        3: [
            '「2022 磊山高峰會『嬉遊記』旅遊競賽」部分商品競賽業績計績方式將依磊山(業)字第 20220055 號公文進行調整。'
        ]
    }
    const removeList = ['2']
    const isRemoveDes = removeList.includes(`${contestId}`)
    const rule = contestRule[contestId] ?? []
    let descriptions = []
    if (isCeoMode) {
        if (isRemoveDes) descriptions = [des1, des2, des3, ...rule]
        else descriptions = [des1, des2, des3, des4, ...rule]
    } else {
        descriptions = [des1, des2, des3, ...rule]
    }

    return descriptions
}

/*
 *** 單位業績查詢 ***
 */
const intervalFilterTitles = {
    dataType: '業績類別',
    receiveDateStart: '受理日(起)',
    receiveDateEnd: '受理日(迄)',
    effectDateStart: '生效日(起)',
    effectDateEnd: '生效日(迄)',
    commitDateStart: '核實月(起)',
    commitDateEnd: '核實月(迄)',
    relationMode: '組織種類',
    isAudit: '審核狀態',
    _seq: [
        'startDate',
        'endDate',
        'receiveDateStart',
        'receiveDateEnd',
        'effectDateStart',
        'effectDateEnd',
        'commitDateStart',
        'commitDateEnd',
        'relationMode',
        'isAudit'
    ]
}

const intervalColumns = {
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'left',
        total: 'key',
        widthPx: 220,
        width: 4,
        isUnmovable: true,
        isUnhide: true
    },
    recentRegister: {
        label: '近期登錄\n標記',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 85,
        width: 4,
        toolTips: {
            hasToolTips: true,
            content: { desktop: '一年內登錄', mobile: null }
        }
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 95,
        width: 4
    },
    license: {
        label: '證照',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 95,
        width: 4,
        alias: {
            licenseTrans: {
                label: '具備證照',
                position: 'center'
            }
        }
    },
    titleTrans: {
        label: '職級代號',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 90,
        width: 4
    },
    registerDate: {
        label: '登錄日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 106,
        divider: true,
        width: 4
    },
    /*
     *** WARNING ***
     *** 組織只會加總position=right的欄位 ***
     *** 邏輯在DetailTable2.vue -> getSumColumn ***
     */
    receiveLifeFyb: {
        label: '區間受理 壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    },
    receiveLifeFyn: {
        label: '區間受理 壽險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    receivePropertyFyb: {
        label: '區間受理 產險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    },
    receivePropertyFyn: {
        label: '區間受理 產險件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        widthPx: 90,
        width: 4
    },
    fourWinFyb: {
        label: '四贏FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    },
    fourWinDivideLifeFyb: {
        label: '四贏FYC\n÷壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['fourWinFyb', 'receiveLifeFyb'],
        unit: '%',
        widthPx: 125,
        width: 4
    },
    specialFyb: {
        label: '特定FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    },
    specialDivideLifeFyb: {
        label: '特定FYC\n÷壽險FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['specialFyb', 'receiveLifeFyb'],
        unit: '%',
        widthPx: 125,
        width: 4
    },
    fyp: {
        label: '保額',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 150,
        width: 4,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '方案、計劃、單位以及非數值保額，不列入計算',
                mobile: null
            }
        }
    },
    totalFyp: {
        label: '總保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    },
    totalFyb: {
        label: '總業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        widthPx: 125,
        width: 4
    }
}

const intervalPerformanceHelper = (row, p, roundHelper) => {
    let _p
    _p = p?.range

    // 壽險
    row.receiveLifeFyp = roundHelper(_p?.supplierType?.life?.fyp, 0) || 0
    row.receiveLifeFyb = roundHelper(_p?.supplierType?.life?.fyb, 0) || 0
    row.receiveLifeFyn = _p?.supplierType?.life?.fyn || 0
    // 產險
    row.receivePropertyFyp =
        roundHelper(_p?.supplierType?.property?.fyp, 0) || 0
    row.receivePropertyFyb =
        roundHelper(_p?.supplierType?.property?.fyb, 0) || 0
    row.receivePropertyFyn = _p?.supplierType?.property?.fyn || 0
    // 四贏
    row.fourWinFyb = roundHelper(_p.productCustomType.fourWin.fyb, 0)
    row.fourWinDivideLifeFyb = _p.fourWinLifeFybRate.toFixed(2)
    // 特定
    row.specialFyb = roundHelper(_p.productCustomType.special.fyb, 0)
    row.specialDivideLifeFyb = _p.specialLifeFybRate.toFixed(2)
    //總和
    row.totalFyp = roundHelper(_p?.supplierType?.totalFyp, 0) || 0
    row.totalFyb = roundHelper(_p?.supplierType?.totalFyb, 0) || 0
    row.fyp = roundHelper(_p?.supplierType?.totalBenefits, 0) || 0

    return row
}

const intervalDescriptionsCommon = []

const intervalDescriptions = [
    `<div class="license-icon description">
    <div class="life-license-icon"></div>為壽險證照、
    <div class="property-license-icon"></div>為產險證照、
    <div class="currency-license-icon"></div>為外幣證照、
    <div class="investment-license-icon"></div>為投資型證照。
    </div>`,
    ...intervalDescriptionsCommon
]
const intervalExcelDescriptions = [
    '"壽"為壽險證照、"產"為產險證照、"外"為外幣證照、"投"為投資型證照。',
    ...intervalDescriptionsCommon
]

export {
    annualFilterTitles,
    annualColumns,
    annualDescriptions,
    detailFilterTitles,
    detailColumns1,
    detailColumns2,
    detailDescriptions1,
    detailDescriptions2,
    detailExcelDescriptions2,
    detailPerformanceHelper,
    dailyFilterTitles,
    dailyColumns,
    dailyDescriptions,
    contestFilterTitles,
    contestColumns,
    contestDescriptions,
    intervalFilterTitles,
    intervalColumns,
    intervalDescriptions,
    intervalExcelDescriptions,
    intervalPerformanceHelper
}
